import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackState, VisualElement } from "@tv4/avod-web-player-common";
import { isChrome, isSupportedOS as isDesktopOS, } from "@tv4/avod-web-player-device-capabilities";
import { useCallback, useState } from "react";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useCustomButton } from "../../providers/CustomButtonContext";
import { useLayoutSettings, useLayoutSize, useVisuals, } from "../../providers/LayoutProvider";
import { LayoutSize } from "../../util/device";
import { getDurationString, getTimeString } from "../../util/time";
import AirplayButton from "../AirplayButton";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import ChromeCastButton from "../ChromeCastButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import FullscreenButton from "../FullscreenButton";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import { PlaybackRateButton } from "../PlaybackRateButton";
import { PlaybackRateSelector } from "../PlaybackRateSelector";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import { ShareButton } from "../ShareButton";
import { TimeLabel } from "../TimeLabel";
import VolumeController from "../VolumeController/VolumeController";
import { Body, Bottom, ButtonGroup, ButtonsContainer, ControlsContainer, Middle, ProgressContainer, Top, } from "./DefaultControls.styles";
export function DefaultControls() {
    const { canGoToLive, canPause, canSeek, canStartOver, currentTime, duration, isCasting, isLive, isAd, isPauseAd, isFullscreen, playbackState, utcCurrentTimeMs, } = usePlayerState();
    const [showPlaybackRateSelector, setShowPlaybackRateSelector] = useState(false);
    const size = useLayoutSize();
    const controls = useControls();
    const { enableFullscreen, onShareClick } = useLayoutSettings();
    const playing = playbackState === PlaybackState.PLAYING;
    const { customButton } = useCustomButton();
    // when smallest layout we show some buttons in the header instead of here
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const includeFullScreenButton = !isSmallestLayout;
    const includeChromeCastButton = isChrome() && !isSmallestLayout;
    const showTimeline = canSeek && !isPauseAd;
    const showSeekbuttons = canSeek && !isPauseAd;
    const showPlayPauseButton = canPause || playbackState === PlaybackState.PAUSED;
    const showGoToLiveButton = canGoToLive && !isPauseAd;
    const showStartOverButton = canStartOver && !isSmallestLayout && !isPauseAd;
    const visuals = useVisuals();
    const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
    const { TracksButton, TracksMenu, tracksMenuOpen, closeTracksMenu } = useTracksMenu();
    const showMetaData = isFullscreen && [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
    const showRemainingTime = !isLive && !isSmallestLayout;
    // whether to allow clicking on video to toggle playback
    const isClickableElement = !isAd && !isPauseAd && !isCasting && isDesktopOS() && canPause;
    const showPlaybackRateButton = controls?.setPlaybackRate && !isAd && !isPauseAd && !isLive;
    const clickHandler = useCallback(() => {
        // close tracks menu if open
        if (tracksMenuOpen) {
            closeTracksMenu();
        }
        // toggle playback
        else if (isClickableElement) {
            if (playing) {
                controls?.pause?.({ programmatic: false });
            }
            else {
                controls?.play?.();
            }
        }
    }, [isClickableElement, controls, playing, closeTracksMenu, tracksMenuOpen]);
    const playbackRateClickHandler = useCallback(() => {
        setShowPlaybackRateSelector((prev) => !prev);
    }, []);
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsxs(Body, { "$isFullscreen": isFullscreen, "$isClickableElement": isClickableElement, onClick: clickHandler, children: [showMetaData && (_jsxs(_Fragment, { children: [_jsx(Top, { children: _jsx(CurrentProgramMetaData, { hidden: false }) }), _jsx(Middle, {}), _jsx(Bottom, {})] })), showPlaybackRateButton && showPlaybackRateSelector && (_jsx(PlaybackRateSelector, { onSelected: () => setShowPlaybackRateSelector(false) }))] }), _jsx(ProgressContainer, { "$compact": isSmallestLayout, children: showTimeline && (_jsxs(_Fragment, { children: [_jsx(TimeLabel, { children: utcCurrentTimeMs
                                ? getTimeString(utcCurrentTimeMs)
                                : getDurationString(currentTime) }), _jsx(ProgressBar, {}), isLive && _jsx(LiveStatus, {}), showRemainingTime && (_jsx(TimeLabel, { children: getDurationString(currentTime - duration) }))] })) }), _jsxs(ButtonsContainer, { children: [_jsxs(ButtonGroup, { "$position": "left", children: [showPlayPauseButton && _jsx(PlayPauseButton, {}), showStartOverButton && _jsx(GoToStartOverButton, {}), showSeekbuttons && !isSmallestLayout && (_jsxs(_Fragment, { children: [_jsx(SeekBackwardButton, {}), _jsx(SeekForwardButton, {})] }))] }), _jsx(ButtonGroup, { "$position": "center", children: isSmallestLayout ? (_jsxs(_Fragment, { children: [showSeekbuttons && _jsx(SeekBackwardButton, {}), showChannelsButton && _jsx(ChannelsButton, {}), customButton, showSeekbuttons && _jsx(SeekForwardButton, {})] })) : (_jsxs(_Fragment, { children: [TracksButton && _jsx(TracksButton, {}), showChannelsButton && _jsx(ChannelsButton, {}), customButton] })) }), _jsxs(ButtonGroup, { "$position": "right", children: [showPlaybackRateButton && (_jsx(PlaybackRateButton, { onClick: playbackRateClickHandler })), showGoToLiveButton && _jsx(GoToLiveButton, {}), onShareClick && _jsx(ShareButton, { onClick: onShareClick }), _jsx(AirplayButton, {}), includeChromeCastButton && _jsx(ChromeCastButton, {}), _jsx(VolumeController, {}), enableFullscreen && includeFullScreenButton && _jsx(FullscreenButton, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
const MetadataContext = createContext({
    asset: undefined,
});
export function MetadataProvider({ asset, poster, children, }) {
    return (_jsx(MetadataContext.Provider, { value: {
            asset,
            poster,
        }, children: children }));
}
export const useMetadata = () => {
    return useContext(MetadataContext);
};

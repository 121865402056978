const RETRY_INTERVAL = 1000;
const UPDATE_INTERVAL = 5000;
export class StreamInfoService {
    url;
    adsRequired;
    data;
    adMarkerMap = new Map();
    updateListeners = [];
    destroyed = false;
    constructor(options) {
        this.url = options.url;
        this.adsRequired = !!options.adsRequired;
    }
    /**
     * Set up the polling subscription to fetch data
     * @param updateListener optional callback
     */
    async setup(updateListener) {
        if (updateListener) {
            this.updateListeners.push(updateListener);
        }
        const doUpdate = async () => {
            try {
                await this.update();
                if (this.destroyed || this.data?.ended)
                    return;
                window.setTimeout(doUpdate, UPDATE_INTERVAL);
            }
            catch (error) {
                console.error(`Error updating stream info. Retrying in ${RETRY_INTERVAL}s`, error);
                await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL));
                if (this.destroyed || this.data?.ended)
                    return;
                await doUpdate();
            }
        };
        await doUpdate();
    }
    async update() {
        if (this.destroyed || this.data?.ended) {
            return;
        }
        const previousEnded = !!this.data?.ended;
        const response = await fetch(this.url);
        const responseData = await response.json();
        if (this.destroyed) {
            return;
        }
        const streamStart = Date.parse(responseData.startTime) / 1000;
        // ignoring responseData.liveEdge. It is the same as startTime + durationMs
        this.data = {
            startTime: streamStart,
            duration: responseData.durationMs / 1000,
            ended: responseData.ended,
        };
        const adMarkerMap = new Map();
        for (const adBreak of responseData.adBreaks || []) {
            const startEpoch = Date.parse(adBreak.adStart) / 1000;
            const endEpoch = Date.parse(adBreak.adEnd) / 1000;
            const watched = !!this.adMarkerMap.get(startEpoch)?.watched;
            const required = this.adsRequired;
            // The first ad break may contain ads before the program start, which needs to be excluded
            // Additionally the test streams have duplicate ad breaks, which need to be excluded
            if (startEpoch >= streamStart && !adMarkerMap.has(startEpoch)) {
                adMarkerMap.set(startEpoch, {
                    start: startEpoch - streamStart,
                    end: endEpoch - streamStart,
                    duration: endEpoch - startEpoch,
                    watched,
                    required,
                });
            }
        }
        const adMarkersChanged = adMarkerMap.size !== this.adMarkerMap.size ||
            adMarkerMap.keys().next().value !==
                this.adMarkerMap.keys().next().value ||
            adMarkerMap.values().next().value?.start !==
                this.adMarkerMap.values().next().value?.start;
        this.adMarkerMap = adMarkerMap;
        this.notifyListeners({
            streamInfo: this.data,
            adMarkers: this.adMarkers,
            adMarkersChanged,
            endedChanged: previousEnded !== this.data.ended,
        });
    }
    notifyListeners(payload) {
        for (const listener of this.updateListeners) {
            listener(payload);
        }
    }
    destroy() {
        this.destroyed = true;
        this.updateListeners = [];
        this.adMarkerMap.clear();
    }
    get duration() {
        return this.data?.duration;
    }
    get startTime() {
        return this.data?.startTime;
    }
    get ended() {
        return this.data?.ended;
    }
    get adMarkers() {
        return [...this.adMarkerMap.values()];
    }
    /** get ad marker at a given position or undefined if there is none */
    getAdMarkerAt(position) {
        return this.adMarkers.find((marker) => marker.start <= position && position < marker.end);
    }
    /** get all ad markers starting between two positions */
    getAdMarkerStartingBetween(start, end) {
        return this.adMarkers.filter((marker) => start <= marker.start && marker.start < end);
    }
    /** Marks the ad marker closest matching the end time as watched */
    markWatchedAdBreak(endTime) {
        const closestMarker = this.adMarkers.reduce((prev, curr) => {
            return Math.abs(curr.end - endTime) < Math.abs(prev?.end - endTime)
                ? curr
                : prev;
        });
        if (!this.data || !closestMarker) {
            console.warn("Tried to set ad marker, but stream info is not ready");
            return;
        }
        closestMarker.watched = true;
        this.notifyListeners({
            streamInfo: this.data,
            adMarkers: this.adMarkers,
            adMarkersChanged: true,
            endedChanged: false,
        });
    }
}

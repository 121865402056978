export interface TPlayerConfig {
  seekAmount: number;
  volumeLevel: number;
  // the threshold for considering the player as being at live edge.
  liveEdgeThreshold: number;
  // how far behind the actual live edge the seekToLive method will seek.
  liveEdgeSeekPadding: number;
  // The distance from start/live edge that should trigger switching of PlaybackMode instead of regular seek.
  playbackModeSwitchThreshold: number;
}

export const DEFAULT_PLAYER_CONFIG: TPlayerConfig = {
  seekAmount: 10, // seconds
  volumeLevel: 0.8,
  liveEdgeThreshold: 45,
  liveEdgeSeekPadding: 5,
  // For the first iteration at least, 10 makes sense as a threshold for switching PlaybackMode,
  // since this is the value for seekAmount, but for future iterations we could consider instead
  // using the size of the DVR window in startOver/liveDai mode.
  playbackModeSwitchThreshold: 10,
};

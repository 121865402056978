import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlaybackState, VisualElement } from "@tv4/avod-web-player-common";
import { useCallback, useState } from "react";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useCustomButton } from "../../providers/CustomButtonContext";
import { useLayoutOrientation, useLayoutSettings, useLayoutSize, useVisuals, } from "../../providers/LayoutProvider";
import { LayoutOrientation, LayoutSize } from "../../util/device";
import { getDurationString, getTimeString } from "../../util/time";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import { PlaybackRateButton } from "../PlaybackRateButton";
import { PlaybackRateSelector } from "../PlaybackRateSelector";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import { ShareButton } from "../ShareButton";
import { TimeLabel } from "../TimeLabel";
import VolumeController from "../VolumeController/VolumeController";
import { Body, Bottom, ButtonGroup, ButtonsContainer, ControlsContainer, LargeButtonsContainer, ProgressContainer, Top, } from "./MobileControls.styles";
export function MobileControls() {
    const { canGoToLive, canSeek, canStartOver, canPause, currentTime, duration, isLive, isAd, isPauseAd, isFullscreen, playbackState, utcCurrentTimeMs, } = usePlayerState();
    const orientation = useLayoutOrientation();
    const size = useLayoutSize();
    const [showPlaybackRateSelector, setShowPlaybackRateSelector] = useState(false);
    const controls = useControls();
    const { onShareClick } = useLayoutSettings();
    const showTimeline = canSeek && !isPauseAd;
    const showSeekButtons = canSeek && !isPauseAd;
    // startover streams have no DVR window, and doesn't handle pausing well
    const showPlayPauseButton = canPause || playbackState === PlaybackState.PAUSED;
    const showGoToLiveButton = canGoToLive && !isPauseAd;
    const showStartOverButton = canStartOver && !isPauseAd;
    const showMetaData = orientation === LayoutOrientation.PORTRAIT &&
        [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
    const { showMobileMuteButton } = useLayoutSettings();
    const showRemainingTime = !isLive && size !== LayoutSize.SMALLEST;
    const visuals = useVisuals();
    const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
    const { closeTracksMenu, TracksMenu, TracksButton } = useTracksMenu();
    const { customButton } = useCustomButton();
    const showPlaybackRateButton = controls?.setPlaybackRate && !isAd && !isPauseAd && !isLive;
    const playbackRateClickHandler = useCallback(() => {
        setShowPlaybackRateSelector((prev) => !prev);
    }, []);
    return (_jsxs(ControlsContainer, { children: [_jsxs(Body, { isFullscreen: isFullscreen, orientation: orientation, onClick: closeTracksMenu, children: [_jsx(Top, { children: showMetaData && _jsx(CurrentProgramMetaData, { hidden: !isFullscreen }) }), _jsxs(LargeButtonsContainer, { children: [showSeekButtons ? _jsx(SeekBackwardButton, {}) : _jsx("div", {}), showPlayPauseButton && _jsx(PlayPauseButton, {}), showSeekButtons ? _jsx(SeekForwardButton, {}) : _jsx("div", {})] }), _jsx(Bottom, { children: showPlaybackRateButton && showPlaybackRateSelector && (_jsx(PlaybackRateSelector, { isMobile: true, onSelected: () => setShowPlaybackRateSelector(false) })) })] }), _jsx(ProgressContainer, { children: showTimeline && (_jsxs(_Fragment, { children: [_jsx(TimeLabel, { children: utcCurrentTimeMs
                                ? getTimeString(utcCurrentTimeMs)
                                : getDurationString(currentTime) }), _jsx(ProgressBar, {}), isLive && _jsx(LiveStatus, {}), showRemainingTime && (_jsx(TimeLabel, { children: getDurationString(currentTime - duration) }))] })) }), _jsxs(ButtonsContainer, { children: [_jsx(ButtonGroup, { "$position": "left", children: showStartOverButton && _jsx(GoToStartOverButton, {}) }), _jsxs(ButtonGroup, { "$position": "center", children: [showPlaybackRateButton && (_jsx(PlaybackRateButton, { onClick: playbackRateClickHandler })), TracksButton && _jsx(TracksButton, {}), customButton, onShareClick && _jsx(ShareButton, { onClick: onShareClick }), showChannelsButton && _jsx(ChannelsButton, {})] }), _jsxs(ButtonGroup, { "$position": "right", children: [showGoToLiveButton && _jsx(GoToLiveButton, {}), showMobileMuteButton && _jsx(VolumeController, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}

/**
 * Player Errors
 * These groups & types are shared between the different platforms.
 *
 * Error handling MUST be kept in sync with other players (iOS, Android ...)
 */

export const generateErrorCode = (domain: string, code: string): string =>
  `${domain.toLowerCase()}[${code}]`;

export enum ERROR_CATEGORY {
  PLAYBACK_API = "PlaybackApi",
  API = "API",
  NETWORK = "Network",
  ADS = "Ads",
  DRM = "DRM",
  MEDIA = "Media",
  USER = "User",
  DEFAULT = "Default",
}

export enum PLAYER_ERROR {
  GENERIC = "Generic",
  WRONG_INPUT = "WrongInput",
  MISSING_DATA = "MissingData",
  UNSUPPORTED_DEVICE = "UnsupportedDevice",
  AD_BLOCKER = "AdBlocker",
  AD_TIMEOUT = "AdTimeout",
  AD_STALLED = "AdStalled",
  AD_PERFORMANCE = "AdPerformance",
  USER_INACTIVITY_STALE_SESSION = "USER_INACTIVITY_STALE_SESSION",
}

export enum DRM_ERROR {
  DRM_GENERIC = "DrmGeneric",
}

export enum MEDIA_ERROR {
  UNKNOWN = "Unknown",
}

export enum API_ERROR {
  YOSPACE = "YospaceFailure",
  UNAUTHORIZED = "UNAUTHORIZED",
  STREAM_NOT_STARTED = "stream_not_started",
  SESSION_NOT_AUTHENTICATED = "SESSION_NOT_AUTHENTICATED",
  ASSET_PLAYBACK_INVALID_GEO_LOCATION = "ASSET_PLAYBACK_INVALID_GEO_LOCATION",
  ASSET_PLAYBACK_PROXY_BLOCKED = "ASSET_PLAYBACK_PROXY_BLOCKED",
  STREAM_LIMIT_SINGLE_STREAM_VIOLATION = "STREAM_LIMIT_SINGLE_STREAM_VIOLATION",
  STREAM_LIMIT_QUOTA_EXCEEDED = "STREAM_LIMIT_QUOTA_EXCEEDED",
  ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION = "ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION",
  LOGIN_REQUIRED = "LOGIN_REQUIRED",
  MISSING_SUBSCRIPTION = "MISSING_SUBSCRIPTION",
  DRM_NOT_ACCEPTABLE = "DRM_NOT_ACCEPTABLE",
}

/**
 * User errors are not reported to KPI tracker instance. They are instead handled in the client.
 */
export enum USER_ERROR {
  GEO_LOCATION = API_ERROR.ASSET_PLAYBACK_INVALID_GEO_LOCATION,
  GEO_LOCATION_PORTABILITY = API_ERROR.ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION,
  UNAUTHORIZED = API_ERROR.UNAUTHORIZED,
  NOT_AUTHENTICATED = API_ERROR.SESSION_NOT_AUTHENTICATED,
  PROXY_BLOCKED = API_ERROR.ASSET_PLAYBACK_PROXY_BLOCKED,
  LOGIN_REQUIRED = API_ERROR.LOGIN_REQUIRED,
  MISSING_SUBSCRIPTION = API_ERROR.MISSING_SUBSCRIPTION,
  STREAM_LIMIT_QUOTA_EXCEEDED = API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED,
  STREAM_LIMIT_SINGLE_STREAM_VIOLATION = API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION,
  USER_INACTIVITY_STALE_SESSION = PLAYER_ERROR.USER_INACTIVITY_STALE_SESSION,
  DRM_NOT_ACCEPTABLE = API_ERROR.DRM_NOT_ACCEPTABLE,
}

import { AssetType, AWPTextTrackKind, clamp, CoreEvents, defaultTextSizes, EventEmitter, loadScript, localPreferences, NO_TEXT_TRACK, } from "@tv4/avod-web-player-common";
const emptyTimePayload = {
    currentTime: 0,
    duration: 0,
    isInAdBreak: false,
};
// set these up globally, because they last for the lifetime of the page
let castPlayer;
let castController;
let sdkLoadPromise = undefined;
async function loadSDK(receiverAppId) {
    sdkLoadPromise ??= new Promise((resolve) => {
        if (!receiverAppId || !("chrome" in window)) {
            resolve(false);
        }
        else {
            window["__onGCastApiAvailable"] = (isAvailable) => {
                if (!isAvailable) {
                    resolve(false);
                }
                else if (!("cast" in window)) {
                    console.warn("Google Cast Sender SDK not loaded, although load callback was called with isAvailable: true");
                    resolve(false);
                }
                else {
                    cast.framework.CastContext.getInstance().setOptions({
                        receiverApplicationId: receiverAppId,
                        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
                    });
                    resolve(true);
                }
            };
            loadScript("https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1").catch((error) => {
                console.error("Failed to load Google Cast Sender SDK", error);
                resolve(false);
            });
        }
    });
    return sdkLoadPromise;
}
async function loadWebSender(noAds, receiverAppId, gdprConsentString) {
    const sdkLoaded = await loadSDK(receiverAppId);
    if (sdkLoaded) {
        return new WebSender(noAds, gdprConsentString);
    }
    return undefined;
}
// TODO: Rename class and package name to CastSender. It doesn't send web. It's not spiderman
class WebSender extends EventEmitter {
    castContext;
    castSession;
    controller;
    player;
    timePayload;
    loadingInProgress = false;
    gdprConsentString;
    noAds = false;
    isProgrammaticPause = false;
    metadata;
    capabilities;
    seekLocked = false;
    preSeekAmount = 0;
    constructor(noAds, gdprConsentString) {
        if (!window.cast) {
            throw new Error("Cast sender initialized before Google Cast Sender SDK was loaded");
        }
        super();
        this.gdprConsentString = gdprConsentString;
        this.noAds = noAds;
        this.timePayload = { ...emptyTimePayload };
        this.castContext = cast.framework.CastContext.getInstance();
        if (!castPlayer || !castController) {
            castPlayer = new cast.framework.RemotePlayer();
            castController = new cast.framework.RemotePlayerController(castPlayer);
        }
        this.player = castPlayer;
        this.controller = castController;
        this.castStateChanged = this.castStateChanged.bind(this);
        this.sessionStateChanged = this.sessionStateChanged.bind(this);
        this.controllerChange = this.controllerChange.bind(this);
        this.messageListener = this.messageListener.bind(this);
        this.setupCastInstanceListeners();
    }
    controllerChange(event) {
        switch (event.field) {
            case "isConnected": {
                if (event.value) {
                    console.info("Chromecast: Connected to session");
                    this.emit(CoreEvents.CHROMECAST_SESSION_STARTED, undefined);
                }
                this.emit(CoreEvents.CHROMECAST_CONNECTION_STATUS, {
                    isConnected: event.value,
                });
                break;
            }
            case "playerState": {
                if (event.value === "PLAYING") {
                    this.emit(CoreEvents.RESUME, this.timePayload);
                    if (this.capabilities?.pause_ads) {
                        this.emit(CoreEvents.PAUSE_AD_HIDDEN, undefined);
                    }
                }
                else if (event.value === "PAUSED") {
                    const programmatic = this.isProgrammaticPause;
                    this.isProgrammaticPause = false;
                    this.emit(CoreEvents.PAUSED, { ...this.timePayload, programmatic });
                    if (this.capabilities?.pause_ads) {
                        this.emit(CoreEvents.PAUSE_AD_SHOWN, undefined);
                    }
                }
                break;
            }
            case "volumeLevel":
            case "isMuted": {
                this.emit(CoreEvents.VOLUME_CHANGED, {
                    volume: this.player.volumeLevel,
                    muted: this.player.isMuted,
                });
                break;
            }
            case "savedPlayerState": {
                // This event is also emitted when you start casting after having previously disconnected,
                // then the value is null, so we need to drop those events
                // We also get info like the contentId (assetId) from event.value, but the types are wrong,
                // and the current time is the html video time with ads and no streaminfo, so it's easier
                // to use this.metadata.
                if (this.metadata) {
                    this.emit(CoreEvents.CHROMECAST_SESSION_ENDED, {
                        contentId: this.metadata.id,
                        // we can't actually use currentTime from saved state, as that time includes the stitched breaks
                        currentTime: this.timePayload.currentTime,
                    });
                }
                this.reset();
                break;
            }
            default:
                break;
        }
    }
    castStateChanged(state) {
        console.debug(`CAST_STATE_CHANGED: ${state.castState}`);
        if (state.castState === "NOT_CONNECTED") {
            console.info("Chromecast devices available");
        }
        else if (state.castState === "NO_DEVICES_AVAILABLE") {
            console.info("No Chromecast devices available");
        }
    }
    sessionStateChanged(event) {
        if (event.sessionState === "SESSION_STARTED" ||
            event.sessionState === "SESSION_RESUMED") {
            this.castSession = event.session;
            this.setupCastSessionListeners(event.session);
        }
        if (event.sessionState === "SESSION_START_FAILED") {
            console.error(`Cast session failed to start: ${event?.errorCode}`, event);
        }
    }
    setupCastInstanceListeners() {
        this.castContext.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.castStateChanged);
        this.castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.sessionStateChanged);
        this.controller.addEventListener(cast.framework.RemotePlayerEventType.ANY_CHANGE, this.controllerChange);
    }
    async cast(loadOptions) {
        this.reset();
        this.loadingInProgress = true;
        if (this.castContext.getCastState() === "CONNECTED") {
            this.emit(CoreEvents.LOADING_PLAYBACK, undefined);
        }
        if (!this.castContext.getCurrentSession()) {
            try {
                this.castSession = await this.requestSession();
            }
            catch (err) {
                console.error("Failed to connect to Chromecast", err);
                throw err;
            }
        }
        const session = this.castContext.getCurrentSession();
        if (session) {
            this.castSession = session;
            this.sendMessage({ type: "assetId", value: loadOptions.assetId });
            if (loadOptions.accessToken) {
                this.sendMessage({
                    type: "authorizationToken",
                    value: loadOptions.accessToken,
                });
            }
            if (this.gdprConsentString) {
                this.sendMessage({
                    type: "gdprConsentString",
                    value: this.gdprConsentString,
                });
            }
            if (!loadOptions.preferredTextTrackLanguage) {
                // Text track lang prefs are stored on different keys for live and vod,
                // but we can't know that before we load, so using vod
                const preferredTextTrackLanguage = localPreferences.getPreferredText(false);
                // If the user choose to disable subs, getPreferredText will return null.
                // For the cast receiver this is instead a separate param
                Object.assign(loadOptions, 
                // we don't have access to noAds from the cast button, so we need to override it here
                { noAds: this.noAds }, preferredTextTrackLanguage
                    ? { preferredTextTrackLanguage }
                    : { enableTextTrack: false });
            }
            if (!loadOptions.preferredAudioLanguage) {
                loadOptions.preferredAudioLanguage =
                    localPreferences.getPreferredAudio();
            }
            // Receiver gets the manifest from API, but chromecast needs just some random manifest url to trigger
            // the loadMedia process in the receiver.
            const mediaInfo = new chrome.cast.media.MediaInfo(loadOptions.assetId, "string");
            const metadata = new chrome.cast.media.GenericMediaMetadata();
            metadata.title = loadOptions.title;
            mediaInfo.metadata = metadata;
            mediaInfo.customData = loadOptions;
            const loadRequest = new chrome.cast.media.LoadRequest(mediaInfo);
            return this.castContext
                ?.getCurrentSession()
                ?.loadMedia(loadRequest)
                .then(() => {
                console.info("chromecast: loadMedia success", loadRequest);
                this.loadingInProgress = false;
            }, (errorCode) => {
                console.error("chromecast: loadMedia failed", errorCode);
                this.loadingInProgress = false;
            });
        }
    }
    stop() {
        // TODO: do we need error handling here?
        this.castContext.getCurrentSession()?.endSession(true);
    }
    getControls() {
        return {
            // Intentionally toggles, rather than specifically playing/pausing, so if the state is wrong it will autocorrect
            play: () => this.togglePlay(),
            pause: (options) => {
                this.isProgrammaticPause = options.programmatic;
                this.togglePlay();
            },
            preSeek: this.preSeek.bind(this),
            applyPreSeek: this.applyPreSeek.bind(this),
            seekTo: (position) => {
                this.handleSeek(position);
            },
            seekToLive: () => {
                // using 48h here as that's the maximum DVR window we have atm
                this.handleSeek(48 * 3600);
            },
            seekToStartOver: () => {
                this.handleSeek(0);
            },
            seekForward: (seekAmount) => {
                this.handleSeek(this.timePayload.currentTime + seekAmount);
            },
            seekBackward: (seekAmount) => {
                this.handleSeek(this.timePayload.currentTime - seekAmount);
            },
            // NOTE: Unlike play/pause where we intentionally toggle, the player internally calls mute and unmute,
            // so we need to implement them to not "toggle"
            mute: () => this.toggleMute(true),
            unmute: () => this.toggleMute(false),
            toggleMute: () => this.toggleMute(),
            setVolume: (volume) => this.setVolume(volume),
            setTextTrack: (track) => {
                if (this.castSession) {
                    this.sendMessage({
                        type: "changeTextTrack",
                        value: track.id !== "-1" ? track.id : undefined,
                    });
                }
            },
            setSubtitleTextSize: (size) => {
                const type = "setSubtitlesSize";
                switch (size) {
                    case defaultTextSizes.SMALL.size:
                        this.sendMessage({ type, value: "small" });
                        break;
                    case defaultTextSizes.MEDIUM.size:
                        this.sendMessage({ type, value: "medium" });
                        break;
                    case defaultTextSizes.LARGE.size:
                        this.sendMessage({ type, value: "large" });
                        break;
                    case defaultTextSizes.XLARGE.size:
                        this.sendMessage({ type, value: "xlarge" });
                }
                this.emit(CoreEvents.SUBTITLE_TEXT_SIZE_CHANGED, { size });
            },
            setAudioTrack: (track) => {
                if (!this.castSession)
                    return;
                this.sendMessage({ type: "changeAudioTrack", value: track.id });
            },
            setPlaybackRate: () => {
                // noop until CC supports this feature
            },
        };
    }
    async isCasting() {
        const MAX_WAIT_TIME_MS = 1300;
        const POLLING_INTERVAL_MS = 10;
        const castContext = cast.framework.CastContext.getInstance();
        let castSession;
        return new Promise((resolve) => {
            const pollingTimer = window.setInterval(() => {
                castSession = castContext.getCurrentSession() ?? undefined;
                if (castSession?.getMediaSession()) {
                    clearInterval(pollingTimer);
                    clearTimeout(maxWaitTimer);
                    resolve(true);
                }
            }, POLLING_INTERVAL_MS);
            const maxWaitTimer = window.setTimeout(() => {
                clearInterval(pollingTimer);
                resolve(!!castSession);
            }, MAX_WAIT_TIME_MS);
        });
    }
    getCurrentChromecastSessionState() {
        if (this.loadingInProgress) {
            // fix for fast back-to-back load clicks preventing starting new load request before previous has finished
            return undefined;
        }
        const currentSession = cast.framework.CastContext.getInstance()?.getCurrentSession();
        const mediaSession = currentSession?.getMediaSession();
        return {
            content: {
                contentId: mediaSession?.media?.contentId,
            },
            session: currentSession,
        };
    }
    async requestSession() {
        console.debug("Chromecast: Session requested...", this.castContext);
        // requestSession doesn't return the session. It returns a "nullable" error code (string enum)
        // https://developers.google.com/cast/docs/reference/web_sender/cast.framework.CastContext#requestSession
        const errorCode = await this.castContext.requestSession();
        const currentSession = this.castContext.getCurrentSession();
        if (!currentSession) {
            throw new Error(`Requesting chromecast session failed with code: ${errorCode}`);
        }
        console.info("Chromecast: Session request successful");
        return currentSession;
    }
    setupCastSessionListeners(castSession) {
        // remove existing listener first to avoid duplicating events
        castSession.removeMessageListener("urn:x-cast:avod.chromecast", this.messageListener);
        castSession.addMessageListener("urn:x-cast:avod.chromecast", this.messageListener);
    }
    sendMessage = (message) => {
        if (this.castSession) {
            this.castSession.sendMessage("urn:x-cast:avod.chromecast", message);
        }
        else {
            console.error(`Chromecast: Can't send message. No session`, message);
        }
    };
    emitPlaybackRestrictions() {
        if (this.metadata && this.capabilities) {
            const liveSeekable = this.metadata.isLive && this.capabilities.seek;
            this.emit(CoreEvents.PLAYBACK_RESTRICTIONS, {
                canSeek: this.capabilities.seek && !this.seekLocked,
                canPause: this.capabilities.pause,
                canGoToLive: liveSeekable,
                canStartOver: liveSeekable && this.metadata.type !== AssetType.CHANNEL,
            });
        }
    }
    async messageListener(namespace, message) {
        try {
            const parsedMessage = JSON.parse(message);
            console.log("message: ", parsedMessage);
            if (namespace !== "urn:x-cast:avod.chromecast") {
                return;
            }
            switch (parsedMessage.type) {
                case "receiverVersion":
                    console.info("Chromecast: Receiver version", parsedMessage.value);
                    break;
                case "assetMetadata": {
                    const newMetadata = parsedMessage.value;
                    if (newMetadata.id !== this.metadata?.id) {
                        this.metadata = newMetadata;
                        this.seekLocked = false;
                        this.emit(CoreEvents.CHROMECAST_CONTENT_UPDATED, newMetadata);
                        this.emitPlaybackRestrictions();
                    }
                    break;
                }
                case "mediaFinished":
                    this.emit(CoreEvents.ENDED, this.timePayload);
                    break;
                case "playbackCapabilities": {
                    this.capabilities = parsedMessage.value;
                    this.emitPlaybackRestrictions();
                    break;
                }
                case "progressData":
                case "liveProgressData":
                    delete parsedMessage.position; // legacy currentTime alias
                    delete parsedMessage.liveSeekableRange; // don't use/need this
                    this.timePayload = parsedMessage;
                    this.emit(CoreEvents.TIME_UPDATED, this.timePayload);
                    break;
                case "nextEpisodeAssetId":
                    if (parsedMessage.value) {
                        // fetchNextAsset(parsedMessage.value);
                    }
                    break;
                case "streamType":
                    // appStore.dispatch(setChromecastStreamType(parsedMessage.value));
                    break;
                case "adBreakStarted":
                    this.emit(CoreEvents.BREAK_START, {
                        adBreak: parsedMessage.data,
                        tracking: false,
                    });
                    this.seekLocked = !this.capabilities?.skip_ads;
                    this.emitPlaybackRestrictions();
                    console.log("ad break started: ", parsedMessage.data);
                    break;
                case "adBreakEnded":
                    this.emit(CoreEvents.BREAK_END, { tracking: false });
                    this.seekLocked = false;
                    this.emitPlaybackRestrictions();
                    break;
                case "adStarted":
                    this.emit(CoreEvents.CHROMECAST_AD_START, { ad: parsedMessage.data });
                    console.log("ad started: ", parsedMessage.data);
                    break;
                case "adEnded":
                    this.emit(CoreEvents.CHROMECAST_AD_END, undefined);
                    break;
                case "receiverError":
                    this.emit(CoreEvents.CHROMECAST_ERROR, {
                        error: {
                            code: parsedMessage.error.code,
                            message: parsedMessage.error.message,
                        },
                    });
                    this.loadingInProgress = false;
                    break;
                case "textTracks":
                    this.synchronizeTextTracks(parsedMessage);
                    break;
                case "audioTracks":
                    this.synchronizeAudioTracks(parsedMessage);
                    break;
                case "adMarkers": {
                    this.emit(CoreEvents.AD_MARKERS_UPDATED, {
                        adMarkers: parsedMessage.value,
                    });
                    break;
                }
                default:
            }
        }
        catch (_e) {
            // ignore, invalid message
        }
    }
    preSeek(amount) {
        if (!this.capabilities?.seek)
            return;
        this.preSeekAmount += amount;
        this.emit(CoreEvents.PRE_SEEKING, { amount });
    }
    applyPreSeek() {
        if (!this.capabilities?.seek)
            return;
        this.handleSeek(this.timePayload.currentTime + this.preSeekAmount);
        this.preSeekAmount = 0;
        // Clear the state preSeeking flag
        this.emit(CoreEvents.SEEKED, {
            ...this.timePayload,
            playing: !this.player.isPaused,
        });
    }
    handleSeek(position) {
        this.player.currentTime = position;
        this.controller.seek();
    }
    togglePlay(play) {
        if (play === undefined || this.player.isPaused === play) {
            this.controller.playOrPause();
        }
    }
    toggleMute(mute) {
        if (mute === undefined || this.player.isMuted !== mute) {
            this.controller.muteOrUnmute();
        }
    }
    /**
     * Volume level between 0 and 1
     */
    setVolume(volume) {
        const clampedVolume = clamp(volume);
        if (clampedVolume !== volume) {
            console.warn(`Volume "${volume}" is out of range, and was clamped to "${clampedVolume}"`);
        }
        this.player.volumeLevel = clampedVolume;
        this.controller.setVolumeLevel();
    }
    synchronizeTextTracks(payload) {
        if (!payload.textTracks?.availableTextTracks?.length) {
            this.emit(CoreEvents.TEXT_TRACK_CHANGED, {
                activeTextTrack: undefined,
                textTracks: [],
            });
            return;
        }
        const castTextTracks = payload.textTracks.availableTextTracks.map((track) => this.convertSessionTextTrackToTextTrack(track));
        castTextTracks.push(NO_TEXT_TRACK);
        const castActiveTracks = payload.textTracks.activeTextTracks;
        const activeTextTrack = castActiveTracks.length > 0
            ? this.convertSessionTextTrackToTextTrack(castActiveTracks[0])
            : castTextTracks.find((track) => track.language === "");
        this.emit(CoreEvents.TEXT_TRACK_CHANGED, {
            activeTextTrack,
            textTracks: castTextTracks,
        });
    }
    synchronizeAudioTracks(payload) {
        if ((payload.audioTracks?.availableAudioTracks?.length || 0) < 2) {
            this.emit(CoreEvents.AUDIO_TRACK_CHANGED, {
                activeAudioTrack: undefined,
                audioTracks: [],
            });
            return;
        }
        const castAudioTracks = payload.audioTracks.availableAudioTracks.map((track) => this.convertSessionAudioTrackToAudioTrack(track));
        const castActiveTracks = payload.audioTracks.activeAudioTracks;
        const activeAudioTrack = this.convertSessionAudioTrackToAudioTrack(castActiveTracks[0]);
        this.emit(CoreEvents.AUDIO_TRACK_CHANGED, {
            activeAudioTrack,
            audioTracks: castAudioTracks,
        });
    }
    convertSessionTextTrackToTextTrack(track) {
        return {
            id: track.id,
            language: track.language,
            label: track.name.toLowerCase() === "" ? "Av" : track.name,
            kind: AWPTextTrackKind.SUBTITLES,
        };
    }
    convertSessionAudioTrackToAudioTrack(track) {
        return {
            id: track.id,
            language: track.language,
            label: track.name,
        };
    }
    reset() {
        this.metadata = undefined;
        this.capabilities = undefined;
        this.seekLocked = false;
        this.timePayload = { ...emptyTimePayload };
        this.preSeekAmount = 0;
    }
    destroy() {
        this.castContext.removeEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, this.castStateChanged);
        this.castContext.removeEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, this.sessionStateChanged);
        this.controller.removeEventListener(cast.framework.RemotePlayerEventType.ANY_CHANGE, this.controllerChange);
        super.destroy();
    }
}
export { loadWebSender };

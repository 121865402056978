import { LoadPreference } from "../constants/load";
import { AdServerOptions } from "./ads-models";
import {
  AssetMarker,
  Orientation,
  PlayResponse,
  Thumbnail,
  TrackingData,
  UserTier,
} from "./playback-api-models";

export enum AssetType {
  CHANNEL = "channel",
  CLIP = "clip",
  EPISODE = "episode",
  MOVIE = "movie",
  SPORTVIDEO = "sportvideo",
  SHORT = "short",
}

export enum DRMFormat {
  WIDEVINE = "widevine",
  PLAYREADY = "playready",
  FAIRPLAY = "fairplay",
}

export enum MediaType {
  DASH = "dash",
  HLS = "hls",
  MP4 = "mp4",
}

export { UserTier };

export interface ContentRating {
  ageRating: string;
  system: string;
  descriptors: string[];
  productPlacement: boolean;
}

export type Asset = Omit<PlayResponse["metadata"], "isDrmProtected"> & {
  endCreditsStart?: number;
  contentRating: ContentRating | null;
  vimondId?: string;
  skippableRegions?: AssetMarker[];
};

export interface VideoTrackingMetadata {
  configuration: {
    // how often should the videotracking endpoint be called
    progressInterval: number;
    endpoint: string;
    anonymousEndpoint: string;
  };
  data: {
    service: "tv4play" | "mtv";
    videoId: string;
    live: boolean;
    entityType:
      | "channel"
      | "clip"
      | "episode"
      | "movie"
      | "sportvideo"
      | "short";
  };
}

export interface Metadata {
  asset: Asset;
  adBreak: {
    trackingData: AdServerOptions;
  };
  tracking: TrackingData;
}

export interface MediaLicense {
  type: DRMFormat;
  licenseUrl: string;
  getToken: () => Promise<string>;
}

export interface MediaLicenseFairplay extends MediaLicense {
  contentId: string;
  certificateServer: string;
}

export interface ThumbnailVariants {
  tv?: Thumbnail;
  desktop?: Thumbnail;
  tablet?: Thumbnail;
  mobile?: Thumbnail;
}

export interface Media {
  type: MediaType;
  isLive: boolean;
  isStartOver: boolean;
  isStitched: boolean;
  manifestUrl: string;
  loadPreference: LoadPreference;
  accessUrl?: string;
  orientation?: Orientation;
  license?: MediaLicense | MediaLicenseFairplay;
  mediaUri?: string;
  thumbnails?: ThumbnailVariants;
}

export enum Service {
  TV4Play = "tv4play",
  FOTBOLLSKANALEN = "Fotbollskanalen", // subservice of TV4PLAY
  KOKET = "koket", // subservice of TV4PLAY
  MTV = "mtv",
  MTVUUTISET = "mtvuutiset", // subservice of MTV
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values -- See comment below
  DEFAULT = "tv4play", // Should be the same value as Service.TV4PLAY!
}

export enum WireTrackingService {
  TV4Play = "tv4play",
  MTV = "mtv",
}

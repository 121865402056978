import { AdServerOptions } from "./ads-models";
import {
  AssetType,
  ContentRating,
  DRMFormat,
  VideoTrackingMetadata,
} from "./data-models";
import {
  FacebookTrackingMetadata,
  GATrackingMetadata,
  LinkPulseTrackingMetadata,
  NielsenTrackingMetadata,
  NpawTrackingMetadata,
} from "./tracking-metadata-models";

export type AssetMarker = {
  categoryId: number;
  categoryName: string;
  timeBegin: number;
  timeEnd: number;
  title: string;
};

export enum UserTier {
  AVOD = "AVOD",
  HVOD = "HVOD",
  SVOD = "SVOD",
}

export type License = {
  type: DRMFormat;
  castlabsToken: string;
  castlabsServer: string;
};

export type FairplayLicense = License & {
  castlabsAssetId: string;
  castlabsCertServer: string;
};

export type TrackingData = Partial<{
  linkPulse: LinkPulseTrackingMetadata;
  nielsen: NielsenTrackingMetadata;
  youbora: NpawTrackingMetadata;
  GA: GATrackingMetadata;
  facebook: FacebookTrackingMetadata;
  videoTracking: VideoTrackingMetadata;
}> & {
  // videoplaza probably also is optional
  // but the player is built as if it isn't
  videoplaza: AdServerOptions;
};

export type AssetDataResponse = {
  id: string;
  bumpers: {
    start: string;
    lowresStart: string;
    end: string;
    prerollEnd: string;
  };
  metadata: {
    id: string;
    title: string;
    description: string;
    programNid: string | null;
    /** seriesTitle is non-optional (same as title if there is no legit series to show, but will differ for clips and some some series) */
    seriesTitle: string;
    /** seasonNumber is 0 if the media is not an episode */
    seasonNumber: number;
    episodeNumber: number | null;
    categories: string[];
    isClip: boolean;
    isLive: boolean;
    /** @deprecated please instead check if there is a licence in the playbackItem */
    isDrmProtected: boolean;
    duration: number;
    image: string | null;
    type?: AssetType;
    hideAds: boolean;
    startOver?: boolean;
  };
  items: AssetMarker[];
  mediaUri: string;
  trackingData: TrackingData;
  contentRating: ContentRating | null;
};

export type Thumbnail = {
  type: "tv" | "desktop" | "tablet" | "mobile";
  width: number;
  height: number;
  url: string;
};

export type Orientation = "landscape" | "portrait";
export type Capabilities = {
  seek: boolean;
  pause: boolean;
  pause_ads: boolean;
  skip_ads: boolean;
  stream_switch: boolean;
};

// PlayResponse is the same as the AssetDataResponse but with these two additional props
export type PlayResponse = AssetDataResponse & {
  capabilities: Capabilities;
  playbackItem: {
    type: "dash" | "hls";
    manifestUrl: string;
    startoverManifestUrl?: string;
    streaminfoUrl?: string;
    originUrl?: string;
    accessUrl?: string;
    isStitched: boolean;
    license?: License | FairplayLicense;
    thumbnails?: Thumbnail[];
    orientation?: Orientation;
  };
  userTier: UserTier;
};

export type DrmIssueResponse = {
  license: {
    castlabsAssetId: string;
    castlabsServer: string;
    castlabsToken: string;
    type: "string";
  };
};

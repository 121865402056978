export type TPendingPromise<T> = {
  promise: Promise<T | undefined>;
  resolve: (result?: T) => void;
  reject: (reason?: T) => void;
  resolved: boolean;
  rejected: boolean;
  completed: boolean;
  result?: T | unknown;
};

export function createPendingPromise<T>(): TPendingPromise<T> {
  const pending: Partial<TPendingPromise<T>> = {
    completed: false,
    resolved: false,
    rejected: false,
  };

  pending.promise = new Promise((resolve, reject) => {
    pending.resolve = (result?: T) => {
      if (!pending.completed) {
        pending.result = result;
        pending.resolved = true;
        pending.completed = true;
        return resolve(result);
      }
    };
    pending.reject = (reason?: unknown) => {
      if (!pending.completed) {
        pending.result = reason;
        pending.rejected = true;
        pending.completed = true;
        return reject(reason);
      }
    };
  });

  return pending as TPendingPromise<T>;
}

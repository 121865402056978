import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { getDurationString, getTimeString } from "../../util/time";
import { ScrubbingOverlayContainer, ScrubbingTime, ThumbnailImage, } from "./styles";
export function TimeThumbnailOverlay({ left, time, thumbnailCues, utcStartTimeMs, }) {
    const [loadedImages, setLoadedImages] = useState({});
    const thumbnail = thumbnailCues?.find((t) => time >= t.startTime && time <= t.endTime);
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const imageUrl = thumbnail?.imageUrl;
    const frame = thumbnail?.frame || { x: 0, y: 0, width: 0, height: 0 };
    const { width, height } = frame;
    // preload image, ignore if already loaded, assume it's cached.
    useEffect(() => {
        if (!imageUrl || loadedImages[imageUrl]) {
            return;
        }
        const image = new Image();
        image.onload = () => {
            setLoadedImages((prev) => ({
                ...prev,
                [imageUrl]: true,
            }));
        };
        image.src = imageUrl;
        return () => {
            image.src = "";
        };
    }, [imageUrl, loadedImages]);
    return (_jsxs(ScrubbingOverlayContainer, { ref: containerRef, children: [frame && imageUrl && (_jsx(ThumbnailImage, { ref: imageRef, style: {
                    width: `${width}px`,
                    height: `${height}px`,
                    left: `clamp(0%, ${left}% - ${width / 2}px, 100% - ${width}px)`,
                    backgroundImage: loadedImages[imageUrl] ? `url(${imageUrl})` : "",
                    backgroundPosition: `${-frame.x}px ${-frame.y}px`,
                } })), _jsx(ScrubbingTime, { style: { left: `${left}%` }, children: utcStartTimeMs
                    ? getTimeString(utcStartTimeMs + time * 1000)
                    : getDurationString(time) })] }));
}

import { getSelectedDrm, isbot, PlayerMode, request, supportedFormats, } from "@tv4/avod-web-player-common";
import { getBrowserName, isAndroid, isChromecast, isIOS, isIpadOS, } from "@tv4/avod-web-player-device-capabilities";
export const DEFAULT_CAPABILITIES = ["live-drm-adstitch-2", "yospace3"];
function getDevice() {
    if (isChromecast())
        return "chromecast";
    if (isIOS() || isIpadOS())
        return "browser-ios";
    if (isAndroid())
        return "browser-android";
    return "browser";
}
export class PlaybackAPI {
    baseUrl;
    includeCredentials;
    getAccessToken;
    destroyed = false;
    protocols = supportedFormats();
    playerMode;
    service;
    device = getDevice();
    constructor(options) {
        this.includeCredentials = !!options.includeCredentials;
        this.baseUrl = options.url;
        this.getAccessToken = options.getAccessToken;
        this.playerMode = options.playerMode || PlayerMode.DEFAULT;
        this.service = options.service;
    }
    async request(url) {
        if (await isbot()) {
            return new Promise(() => {
                // promise will never resolve for bot
            });
        }
        else {
            const [response, error] = await request(url, {
                method: "GET",
                credentials: this.includeCredentials ? "include" : "same-origin",
            }, this.getAccessToken, () => this.destroyed);
            if (error || !response) {
                throw error;
            }
            return response;
        }
    }
    async getUrl(path) {
        const url = new URL(path, this.baseUrl);
        url.searchParams.append("service", this.service);
        url.searchParams.append("device", this.device || getDevice());
        url.searchParams.append("protocol", this.protocols.join(","));
        url.searchParams.append("drm", (await getSelectedDrm()) ?? "");
        url.searchParams.append("browser", getBrowserName());
        return url;
    }
    async playRequest(id) {
        const url = await this.getUrl(`play/${id}`);
        url.searchParams.append("capabilities", DEFAULT_CAPABILITIES.join(","));
        url.searchParams.append("preview", this.playerMode === PlayerMode.PREVIEW ? "true" : "false");
        return this.request(url);
    }
    async shortRequest(id, orientation) {
        const url = await this.getUrl(`play/${id}/short`);
        url.searchParams.append("orientation", orientation);
        url.searchParams.append("preview", this.playerMode === PlayerMode.PREVIEW ? "true" : "false");
        return this.request(url);
    }
    async drmRequest(id) {
        const url = await this.getUrl(`drm/${id}/issue`);
        return this.request(url);
    }
    destroy() {
        this.destroyed = true;
    }
}

/**
 * used to make api request
 */

import { AWPError, ErrorLevel } from "./AWPError";
import { API_ERROR, ERROR_CATEGORY, USER_ERROR } from "./ErrorConstants";
import { requestWithRetry } from "./retry";

export enum RequestErrorCodes {
  FAILED_TO_FETCH = "FAILED_TO_FETCH",
  UNHANDLED_ERROR = "UNHANDLED_ERROR",
}

const REQUEST_ERROR_PREFIX = "REQUEST:";

function getErrorCode({
  json,
  response,
  error,
}: {
  json: unknown;
  response: unknown;
  error: unknown;
}): string {
  if (
    json &&
    typeof json === "object" &&
    "errorCode" in json &&
    typeof json.errorCode === "string"
  ) {
    // TODO remove this code conversion
    if (json.errorCode === String(API_ERROR.ASSET_PLAYBACK_PROXY_BLOCKED)) {
      return String(USER_ERROR.PROXY_BLOCKED);
    }

    return json.errorCode;
  }

  if (response && typeof response === "object" && "status" in response) {
    return `${REQUEST_ERROR_PREFIX}${response.status}`;
  }

  if (error && typeof error === "object") {
    if (
      // "code" only exists on MediaError, so this is likely
      // never true. Keep the code for legacy purposes.
      "code" in error &&
      (typeof error.code === "string" || typeof error.code === "number")
    ) {
      return `${REQUEST_ERROR_PREFIX}${error.code}`;
    }

    if (
      "message" in error &&
      typeof error.message === "string" &&
      // Chrome, Edge, Chrome Mobile
      (error.message.toLowerCase().includes("failed to fetch") ||
        // Firefox
        error.message.toLowerCase().includes("attempting to fetch") ||
        // Safari, Safari Mobile
        error.message.toLowerCase().includes("load failed"))
    ) {
      return `${REQUEST_ERROR_PREFIX}${RequestErrorCodes.FAILED_TO_FETCH}`;
    }

    if ("name" in error && typeof error.name === "string") {
      return `${REQUEST_ERROR_PREFIX}${error.name}`;
    }
  }

  return `${REQUEST_ERROR_PREFIX}${RequestErrorCodes.UNHANDLED_ERROR}`;
}

export async function request<T>(
  url: string | URL,
  options: RequestInit,
  getAccessToken: () => string | undefined,
  cancelled: () => boolean
): Promise<[T | null, null | AWPError]> {
  let json: Record<string, unknown> | null = null;
  let response: Response | undefined = undefined;
  let text: string | undefined = undefined;

  let error: unknown;

  try {
    response = await requestWithRetry({
      request: () =>
        fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            ...(getAccessToken()
              ? {
                  "x-jwt": `Bearer ${getAccessToken()}`,
                }
              : {}),
          },
        }),
      numRetry: 4,
      delayMs: 1000,
      backOffFactor: 1.5,
      cancelled,
    });

    text = await response.text();
    json = JSON.parse(text);
  } catch (e) {
    error = e;
  }

  // successful response and no json parse error
  if (response?.ok && !error) {
    return [json as T, null];
  }

  const responseContent = (text || "").replace(/<[^>]+>/g, "").trim();

  /**
   * AWPError is created from information available.
   * json is expected to be backend error json.
   * if json error response is not available then information from response or error is used.
   */

  let category = ERROR_CATEGORY.PLAYBACK_API;
  let errorLevel = ErrorLevel.PLAYER;

  const errorCode = getErrorCode({ error, response, json });

  if (errorCode === String(USER_ERROR.PROXY_BLOCKED)) {
    category = ERROR_CATEGORY.USER;
    errorLevel = ErrorLevel.USER;
  }

  return [
    null,
    new AWPError({
      context: "http",
      message: String(json?.message || response?.statusText || ""),
      category,
      code: errorCode,
      raw: json || error,
      details: {
        url,
        status: response?.status || -1,
        response: responseContent,
      },
      errorLevel,
    }),
  ];
}

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CoreEvents, PlaybackState, } from "@tv4/avod-web-player-common";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useChromecastManager, usePlayerState, } from "../providers/CoreProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import ChromeCastButton from "./ChromeCastButton";
import Poster from "./Poster";
const Background = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${({ $isError }) => ($isError ? "1.5rem" : "2em")};
`;
const CastController = styled.div `
  position: absolute;
  bottom: 1em;
`;
const CastStatusMessage = styled.div `
  position: relative;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;
export const ChromeCastOverlay = () => {
    const playerState = usePlayerState();
    const [error, setError] = useState();
    const manager = useChromecastManager();
    const { poster } = useMetadata();
    const { playbackState, isAd } = playerState;
    useEffect(() => {
        const errorHandler = (payload) => {
            setError(payload.error);
        };
        const resetError = () => {
            setError(undefined);
        };
        manager?.on(CoreEvents.CHROMECAST_ERROR, errorHandler);
        manager?.on(CoreEvents.CHROMECAST_CONNECTION_STATUS, resetError);
        return () => {
            manager?.off(CoreEvents.CHROMECAST_ERROR, errorHandler);
            manager?.off(CoreEvents.CHROMECAST_CONNECTION_STATUS, resetError);
        };
    }, [manager]);
    const translations = useTranslations();
    let castStatusKey;
    if (isAd) {
        castStatusKey = "chromecast__commercial";
    }
    else {
        castStatusKey =
            playbackState === PlaybackState.LOADING
                ? "chromecast__loading"
                : "chromecast__playing";
    }
    return (_jsxs(Background, { "$isError": error != undefined, children: [error ? (translations("chromecast__error", { error: error.message })) : (_jsxs(_Fragment, { children: [_jsx(Poster, { poster: poster, blurred: false }), _jsx(CastStatusMessage, { children: translations(castStatusKey) })] })), (error || playerState.playbackState === PlaybackState.ENDED) && (_jsx(CastController, { children: _jsx(ChromeCastButton, {}) }))] }));
};
export default ChromeCastOverlay;

type RetryRequestOptions<T> = {
  // Request to be retried
  request: () => Promise<T>;
  // Number of times to retry (total requests = numRetry + 1)
  numRetry: number;
  // Initial retry delay
  delayMs: number;
  // Multiplicative with delay for each consecutive retry
  backOffFactor: number;
  // Cancel the retry chain, promise will never resolve. This
  // should only evaluate to true if also dropping all references to
  // the requestWithRetry promise to prevent memory leaks.
  cancelled: () => boolean;
  // Used internally to keep track of the error to throw
  // when retries end
  lastError?: Error;
};

export const requestWithRetry = async <T>(
  options: RetryRequestOptions<T>
): Promise<T> => {
  return options
    .request()
    .then((r) => {
      if (
        r instanceof Response &&
        !r.ok &&
        // Retry if authentication issue
        (r.status === 401 || r.status === 403) &&
        options.numRetry > 0
      ) {
        // Throw in order to enter catch block with retry logic
        throw new Error("Request failed, retrying", {
          cause: r,
        });
      }

      return r;
    })
    .catch((e) => {
      return new Promise<T>((resolve) => {
        if (options.numRetry === 0) {
          throw options.lastError;
        }

        setTimeout(() => {
          if (options.cancelled()) return;

          resolve(
            requestWithRetry({
              ...options,
              delayMs: options.delayMs * options.backOffFactor,
              numRetry: options.numRetry - 1,
              lastError: e,
            })
          );
        }, options.delayMs);
      });
    });
};

import { AWPError, ERROR_CATEGORY, ErrorLevel, PLAYER_ERROR, resolveMediaType, } from "@tv4/avod-web-player-common";
import { SKIPPABLE_REGION_MINIMUM_DURATION } from "../constants/skippable-regions";
const getEndCreditsStart = (playbackItems) => {
    const endCreditsStartOrEndCreditsItem = playbackItems.find((item) => item.categoryId === 8 || item.categoryId === 88);
    return endCreditsStartOrEndCreditsItem?.timeBegin;
};
const getSkippableRegions = (playbackItems) => {
    return playbackItems.filter((item) => (item.categoryId === 83 || item.categoryId === 85) &&
        item.timeEnd - item.timeBegin >= SKIPPABLE_REGION_MINIMUM_DURATION);
};
function createGetDrmTokenFn(playbackApi, playbackAsset) {
    const { playbackItem } = playbackAsset;
    let playTokenUsed = false;
    return async () => {
        if (playTokenUsed || !playbackItem.license?.castlabsToken) {
            return (await playbackApi.drmRequest(playbackAsset.id)).license
                .castlabsToken;
        }
        playTokenUsed = true;
        return playbackItem.license.castlabsToken;
    };
}
export function mapPlaybackApiMediaToMedia(options) {
    let manifestUrl;
    const { playbackItem } = options.playbackAsset;
    if (options.startOver) {
        manifestUrl = playbackItem.startoverManifestUrl;
    }
    else if (options.origin && playbackItem.originUrl) {
        manifestUrl = playbackItem.originUrl;
    }
    else {
        manifestUrl = playbackItem.manifestUrl;
    }
    if (!manifestUrl) {
        return;
    }
    const type = resolveMediaType(manifestUrl);
    return {
        type,
        loadPreference: options.loadPreference,
        isLive: options.playbackAsset.metadata.isLive,
        isStartOver: manifestUrl === playbackItem.startoverManifestUrl,
        isStitched: !options.origin && playbackItem.isStitched,
        manifestUrl,
        accessUrl: playbackItem.accessUrl,
        license: playbackItem.license && {
            type: playbackItem.license.type,
            licenseUrl: playbackItem.license.castlabsServer,
            ...("castlabsAssetId" in playbackItem.license && {
                contentId: playbackItem.license.castlabsAssetId,
                certificateServer: playbackItem.license.castlabsCertServer,
            }),
            getToken: createGetDrmTokenFn(options.playbackApi, options.playbackAsset),
        },
        mediaUri: options.playbackAsset.mediaUri,
        orientation: options.playbackAsset.playbackItem.orientation,
        thumbnails: playbackItem.thumbnails?.reduce((acc, thumbnail) => ({
            ...acc,
            [thumbnail.type]: thumbnail,
        }), {}),
    };
}
export function mapPlaybackAssetToMetadata(asset) {
    const trackingData = {
        ...asset.trackingData.videoplaza,
    };
    return {
        asset: {
            ...asset.metadata,
            id: asset.id,
            endCreditsStart: getEndCreditsStart(asset.items ?? []),
            contentRating: asset.contentRating ?? null,
            vimondId: asset.trackingData?.youbora?.id,
            skippableRegions: getSkippableRegions(asset.items ?? []),
        },
        adBreak: {
            trackingData,
        },
        tracking: asset.trackingData,
    };
}
export function mapPlayResponse({ playbackApi, asset, loadPreference = "stability", }) {
    const metadata = mapPlaybackAssetToMetadata(asset);
    const media = mapPlaybackApiMediaToMedia({
        playbackApi,
        playbackAsset: asset,
        loadPreference,
    });
    const startOverMedia = mapPlaybackApiMediaToMedia({
        playbackApi,
        playbackAsset: asset,
        startOver: true,
        loadPreference,
    });
    const originMedia = mapPlaybackApiMediaToMedia({
        playbackApi,
        playbackAsset: asset,
        origin: true,
        loadPreference,
    });
    const capabilities = asset.capabilities;
    if (!media) {
        throw new AWPError({
            context: "player",
            message: "media not found",
            category: ERROR_CATEGORY.DEFAULT,
            code: PLAYER_ERROR.MISSING_DATA,
            errorLevel: ErrorLevel.PLAYER,
        });
    }
    if (!media.isLive && capabilities.skip_ads) {
        // If skip_ads is true for a non-live asset it's most likely an adblocker or similar attempt to skip ads
        capabilities.skip_ads = false;
    }
    return { metadata, media, capabilities, startOverMedia, originMedia };
}

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackState } from "@tv4/avod-web-player-common";
import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { LayoutSize } from "../util/device";
import { getDurationString, getTimeString } from "../util/time";
import { useTracksMenu } from "./audio-text-tracks/TracksMenu/TracksMenu";
import ChromeCastButton from "./ChromeCastButton";
import { Body, ButtonGroup, ButtonsContainer, ControlsContainer, ProgressContainer, } from "./DefaultControls/DefaultControls.styles";
import FullscreenButton from "./FullscreenButton";
import GoToLiveButton from "./GoToLiveButton";
import GoToStartOverButton from "./GoToStartOverButton";
import LiveStatus from "./LiveStatus";
import PlayPauseButton from "./PlayPauseButton";
import ProgressBar from "./progress-bar/ProgressBar";
import SeekBackwardButton from "./SeekBackwardButton";
import SeekForwardButton from "./SeekForwardButton";
import { TimeLabel } from "./TimeLabel";
import VolumeController from "./VolumeController/VolumeController";
export function ChromeCastControls() {
    const { canPause, canSeek, canStartOver, currentTime, duration, isLive, isPauseAd, playbackState, utcCurrentTimeMs, } = usePlayerState();
    const size = useLayoutSize();
    const showTimeline = canSeek && !isPauseAd;
    const showSeekbuttons = canSeek && !isPauseAd;
    const showPlayPauseButton = canPause || playbackState === PlaybackState.PAUSED;
    const { closeTracksMenu, TracksButton, TracksMenu } = useTracksMenu();
    // when smallest layout we show some buttons in the header instead of here
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const showRemainingTime = !isLive && !isSmallestLayout;
    const isSeekableLiveStream = isLive && canSeek && !isPauseAd;
    const showStartOverButton = canStartOver && !isSmallestLayout && !isPauseAd;
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsx(Body, { onClick: closeTracksMenu }), _jsx(ProgressContainer, { "$compact": isSmallestLayout, children: showTimeline && (_jsxs(_Fragment, { children: [_jsx(TimeLabel, { children: utcCurrentTimeMs
                                ? getTimeString(utcCurrentTimeMs)
                                : getDurationString(currentTime) }), _jsx(ProgressBar, {}), isLive && _jsx(LiveStatus, {}), showRemainingTime && (_jsx(TimeLabel, { children: getDurationString(currentTime - duration) }))] })) }), _jsxs(ButtonsContainer, { children: [_jsxs(ButtonGroup, { "$position": "left", children: [showPlayPauseButton && _jsx(PlayPauseButton, {}), showStartOverButton && _jsx(GoToStartOverButton, {}), showSeekbuttons && !isSmallestLayout && (_jsxs(_Fragment, { children: [_jsx(SeekBackwardButton, {}), _jsx(SeekForwardButton, {})] }))] }), _jsx(ButtonGroup, { "$position": "center", children: isSmallestLayout ? (_jsxs(_Fragment, { children: [showSeekbuttons && _jsx(SeekBackwardButton, {}), showSeekbuttons && _jsx(SeekForwardButton, {})] })) : (TracksButton && _jsx(TracksButton, {})) }), _jsxs(ButtonGroup, { "$position": "right", children: [isSeekableLiveStream && _jsx(GoToLiveButton, {}), !isSmallestLayout && _jsx(ChromeCastButton, {}), _jsx(VolumeController, {}), !isSmallestLayout && _jsx(FullscreenButton, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}
